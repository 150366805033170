<template>
  <li class="nav-item" role="presentation">
    <a
      :href="`#tabs-${tabId}`"
      :id="`tabs-${tabId}-tab`"
      :aria-controls="`tabs-${tabId}`"
      :data-bs-target="`#tabs-${tabId}`"
      class="nav-link"
      data-bs-toggle="pill"
      aria-selected="true"
      role="tab"
    >
      {{ text }}
    </a>
  </li>
</template>

<script>
  export default {
    name: "TabItemList",
    props: {
      text: {
        type: String,
        required: true
      },
      tabId: {
        type: String,
        required: true
      }
    },
  }
</script>