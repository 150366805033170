import MyIcons from '../icons.js';

const fetchIcon = name => {
  const icons = MyIcons;

  if (icons === undefined) {
    console.error('Icons not loaded');
    return;
  }

  const icon = icons[name];

  if (icon === undefined) {
    console.warn(`Icon '${name}' does not exist`);
    return null;
  }

  return icon
};

const directives = {
  icon: {
    bind(el, { arg }) {
      const icon = fetchIcon(arg);
      if (!icon) return;
      el.innerHTML = `<svg viewBox="${icon.viewBox}">${icon.content}</svg>`;
    },
    update(el, {arg}) {
      const icon = fetchIcon(arg);
      if (!icon) return;
      el.innerHTML = `<svg viewBox="${icon.viewBox}">${icon.content}</svg>`;
    }
  },
  collapsible: {
    bind(el, binding) {
      el.addEventListener('click', (e) => {
        const content = el.querySelector('[data-collapsible]');
        if (!content || e.target.tagName.toLowerCase() === 'a') return;
        content.classList.toggle('hidden');
        el.querySelector('.arrow').classList.toggle('transform');
        el.querySelector('.arrow').classList.toggle('rotate-180');
      });
    },
  },
  calculateLayoutGradientPosition: {
    bind(el, binding) {
      const bannerMaxHeight = 640;

      const onResize = () => {
        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const bannerHeight = el.offsetHeight;
        const bgImageLayout = document.getElementsByClassName('landing-page__background-image');

        if (!!bgImageLayout && bgImageLayout.length > 0) {
          const containerImage = bgImageLayout[0].firstChild;

          if(viewportWidth < 768) {
            containerImage.classList.remove('has-before');
            containerImage.removeAttribute('style');
          }
          else if(bannerHeight < bannerMaxHeight) {
            const calculatedHeightValue = bannerMaxHeight - bannerHeight;

            if (calculatedHeightValue > 0) {
              containerImage.style.setProperty("--heightBefore", `${calculatedHeightValue}px`);
              containerImage.style.setProperty("--topAfter", `-${calculatedHeightValue}px`);
              containerImage.classList.add('has-before');
            }
          }
        }
      }
      
      setTimeout(() => {
        onResize();
      }, 200);

      window.addEventListener('resize', onResize);
    }
  },
  calculateLogoutPosition: {
    bind(el, binding) {
      const onResize = () => {
        const header = document.getElementById('header');
        if (header) el.style.top = ((header.offsetHeight + 10) / 16) + 'rem';
      }

      setTimeout(() => {
        onResize();
      }, 200);

      window.addEventListener('resize', onResize);
    }
  },
  fullWidthImage: {
    bind(el, binding) {
      const setImageTop = (el, fullContainer, fullContainerParentElement, fullContainerImage, prevEl, prevElChild) => {
        el.classList.add('md:flex-col-reverse');
        fullContainerParentElement.style.paddingTop = 0;
        const margTop = fullContainer.offsetHeight / 2;
        const size = fullContainerImage.offsetHeight > margTop ? margTop : fullContainerImage.offsetHeight - 100;
        fullContainer.style.marginTop = `-${size}px`;

        if(el.classList.contains('overlap-block') && prevElChild) {
          prevElChild.style.paddingBottom = 0;

          const prevElChildContainer = prevElChild.querySelector('.full-container');
          prevElChildContainer.style.paddingBottom = 0;
          
          const elemTop = fullContainer.offsetTop - 100;
          const prevTop = prevElChild.offsetTop + prevElChild.offsetHeight;
          let margTopPrev = prevElChildContainer.offsetHeight / 2;

          if (elemTop > (prevTop - margTopPrev )) {
            margTopPrev = elemTop - prevTop;
          }

          margTopPrev = margTopPrev >= 0 ? margTopPrev : (margTopPrev * -1);
          el.style.marginTop = `-${margTopPrev}px`;
        }
      }
 
      const setImageBottom = (el, fullContainer, fullContainerParentElement, fullContainerImage, nextEl, nextElChild) => {
        fullContainerParentElement.style.paddingBottom = 0;
        const margTop = fullContainer.offsetHeight / 2;
        const size = fullContainerImage.offsetHeight > margTop ? margTop : fullContainerImage.offsetHeight - 100;
        fullContainerImage.style.marginTop = `-${size}px`;

        if(el.classList.contains('overlap-block') && nextElChild) {
          nextElChild.style.marginTop = 0;
          nextElChild.style.paddingTop = 0;

          const nextElChildContainer = nextElChild.querySelector('.full-container');
          const elemTop = fullContainer.offsetTop + fullContainer.offsetHeight + 100;
          const nextTop = nextElChildContainer.offsetTop;
          let margTopNext = nextElChildContainer.offsetHeight / 2;

          if (elemTop > (nextTop - margTopNext)) {
            margTopNext = elemTop - nextTop ;
          }

          margTopNext = margTopNext >= 0 ? margTopNext : (margTopNext * -1);
          nextElChild.style.marginTop = `-${margTopNext}px`;
        }
      }

      const setImageCenter = (el, fullContainer, fullContainerParentElement, fullContainerImage, prevEl, prevElChild, nextEl, nextElChild) => {
        const fullContainerHeight = fullContainer.offsetHeight;
        const fullContainerImageHeight = fullContainerImage.offsetHeight;
        fullContainerImage.style.marginTop = 0;
        fullContainerParentElement.style.paddingBottom = 0;
        fullContainerParentElement.style.paddingTop = 0;

        if (fullContainerHeight > fullContainerImageHeight) {
          el.classList.remove('md:flex-col-reverse');
          if (prevElChild) {
            const prevElChildContainer = prevElChild.querySelector('.full-container');
            prevElChildContainer.style.paddingBottom = 0;
          }

          const margTop = fullContainerImageHeight + ((fullContainerHeight - fullContainerImageHeight) / 2);
          fullContainerImage.style.marginTop = `-${margTop}px`;
          fullContainerParentElement.style.paddingBottom = '75px';
          fullContainerParentElement.style.paddingTop = '75px';
        } else {
          el.classList.add('md:flex-col-reverse');
          const calculateHeight = (fullContainerImageHeight - fullContainerHeight) / 2;
          const margTop = fullContainerHeight + (calculateHeight);
          fullContainer.style.marginTop = `-${margTop}px`;

          if(prevElChild) {
            el.style.marginTop = 0;
            prevElChild.style.marginBottom = 0;
            prevElChild.style.paddingBottom = 0;
            
            const prevElChildContainer = prevElChild.querySelector('.full-container');
            prevElChildContainer.style.paddingBottom = 0;

            const elemTop = fullContainer.offsetTop - 140;
            const prevTop = prevElChild.offsetTop + prevElChild.offsetHeight;
            let margTopPrev = prevElChildContainer.offsetHeight / 2;

            if (elemTop > (prevTop - margTopPrev)) {
              margTopPrev = elemTop - prevTop;
            }

            margTopPrev = margTopPrev > calculateHeight ? 0 : margTopPrev >= 0 ? margTopPrev : (margTopPrev * -1);
            el.style.marginTop = `-${margTopPrev}px`;
          }

          if(nextElChild) {
            setTimeout(() => {
              nextElChild.style.marginTop = 0;
              nextElChild.style.paddingTop = 0;

              const nextElChildContainer = nextElChild.querySelector('.full-container');
              const elemTop = fullContainer.offsetTop + fullContainer.offsetHeight + 140;
              const nextTop = nextElChildContainer.offsetTop;
              let margTopNext = nextElChildContainer.offsetHeight / 2;

              if (elemTop > (nextTop - margTopNext)) {
                margTopNext = elemTop - nextTop;
              }

              margTopNext = margTopNext < calculateHeight ? calculateHeight : margTopNext >= 0 ? margTopNext : (margTopNext * -1);
              nextElChild.style.marginTop = `-${margTopNext}px`;
            }, 200);
          }
        }
      }

      const onResize = () => {
        const fullContainer = el.querySelector('.full-container');
        const fullContainerImage = el.querySelector('.full-container-image');
        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

        if (!fullContainer || !fullContainerImage) return;

        const fullContainerParentElement = fullContainer.parentElement;
        const prevEl = el.parentElement.previousElementSibling;
        const nextEl = el.parentElement.nextElementSibling;
        let prevElChild = null;
        let nextElChild = null;

        if(prevEl) prevElChild = prevEl.firstChild;
        if(nextEl) nextElChild = nextEl.firstChild;

        if (viewportWidth < 768) {
          el.removeAttribute('style');
          fullContainerParentElement.removeAttribute('style');
          fullContainer.removeAttribute('style');
          fullContainerImage.removeAttribute('style');
          if(el.classList.contains('overlap-block') || el.classList.contains('image-is-center')) {
            if(prevElChild) {
              const prevElChildContainer = prevElChild.querySelector('.full-container');
              prevElChildContainer.removeAttribute('style');
              prevElChild.removeAttribute('style');
            }
            if(nextElChild) nextElChild.removeAttribute('style');
          }
        }
        else {
          if (el.classList.contains('image-is-top')) {
            setImageTop(el, fullContainer, fullContainerParentElement, fullContainerImage, prevEl, prevElChild);
          } 
          else if (el.classList.contains('image-is-bottom')) {
            setImageBottom(el, fullContainer, fullContainerParentElement, fullContainerImage, nextEl, nextElChild);
          } else if (el.classList.contains('image-is-center')) {
            setImageCenter(el, fullContainer, fullContainerParentElement, fullContainerImage, prevEl, prevElChild, nextEl, nextElChild);
          } else {
            return;
          }
        }
      }

      setTimeout(() => {
        onResize();
      }, 200);

      window.addEventListener('resize', onResize);
    }
  },
  overlapBlock: {
    bind(el, binding) {
      const convertPxToInt = (value) => Number(value.replace(/px$/, ''));

      const onLoad = () => {
        const paddingToAdd = 144;
        const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const { value } = binding;
        const parentContainer = el.parentElement;
        const mainContainer = parentContainer.parentElement;
        el.removeAttribute('style');

        if ((value.top && value.top === 'true') && viewportWidth >= 768) {
          const prevBlock = mainContainer.previousElementSibling;
          if (prevBlock) {
            const prevPromoContent = prevBlock.firstChild;
            const prevCanOverlap = ['image-is-bottom', 'image-is-center'].some(className => prevPromoContent.classList.contains(className));
            const currentCanOverlap = ['image-is-top', 'image-is-center'].some(className => parentContainer.classList.contains(className));

            if (!prevCanOverlap && !currentCanOverlap) {
              const currentPaddingTop = convertPxToInt(window.getComputedStyle(el, null).getPropertyValue('padding-top'));
              let currentMarginTop = convertPxToInt(window.getComputedStyle(el, null).getPropertyValue('margin-top'));
              if (currentMarginTop < 0) currentMarginTop *= -1;

              el.style.paddingTop = `${currentPaddingTop + paddingToAdd}px`;
              el.style.marginTop = `-${currentMarginTop + paddingToAdd}px`;
            }
          }
        }

        if ((value.bottom && value.bottom === 'true')) {
          const nextBlock = mainContainer.nextElementSibling;
          if (nextBlock) {
            const nextPromoContent = nextBlock.firstChild;
            const nextCanOverlap = ['image-is-top', 'image-is-center'].some(className => nextPromoContent.classList.contains(className));
            const currentCanOverlap = ['image-is-bottom', 'image-is-center'].some(className => parentContainer.classList.contains(className));

            if (!nextCanOverlap) {
              const sectionBlock = nextPromoContent.firstChild;
              const currentPaddingBottom = convertPxToInt(window.getComputedStyle(el, null).getPropertyValue('padding-bottom'));
              
              sectionBlock.removeAttribute('style');
              if (viewportWidth >= 768 && !currentCanOverlap) {
                sectionBlock.style.marginTop = `-${paddingToAdd}px`;
                el.style.paddingBottom = `${currentPaddingBottom + paddingToAdd}px`;
              }
            }
          }
        }
      }

      setTimeout(onLoad, 200);
      window.addEventListener('resize', onLoad);
    }
  }
};

export default directives;
