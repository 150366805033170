import VHeader from "./Header/VHeader.vue";
import HeaderSearch from "./Header/HeaderSearch.vue";
import HeaderSearchPopup from "./Header/HeaderSearchPopup.vue";
import HeaderMegamenuDesktop from "./Header/HeaderMegamenuDesktop.vue";
import HeaderMegamenuMobile from "./Header/HeaderMegamenuMobile.vue";
import MegaMenu from "./MegaMenu/MegaMenu.vue";
import BlockMenu from "./BlockMenu/BlockMenu.vue";
import VAxis from "./Axis/VAxis.vue";
import AxisSidebar from "./Axis/AxisSidebar.vue";
import AxisSkeleton from "./Axis/AxisSkeleton.vue";
import NewsLetter from "./Forms/NewsLetter.vue";
import VMessage from "./VMessage/VMessage.vue";
import VEvents from "./Events/VEvents.vue";
import DocumentEmbed from "./DocumentEmbed/DocumentEmbed.vue";
import DocumentViewer from "./DocumentViewer/DocumentViewer.vue";
import Emissions from "./Emissions/Emissions.vue";
import Gallery from "./Gallery/Gallery.vue";
import BvcStats from "./RightFeatured/BvcStats.vue";
import VTabs from "./CustomTags/Tabs/VTabs.vue";
import TabItemList from "./CustomTags/Tabs/TabItemList.vue";
import TabContent from "./CustomTags/Tabs/TabContent.vue";
import SearchPage from "./Search/SearchPage.vue";
import VAccordion from "./CustomTags/Accordion/VAccordion.vue";
import AccordionContent from "./CustomTags/Accordion/AccordionContent.vue";
import VCounter from "./VCounter/VCounter.vue";
import FileList from "./FileList/FileList.vue";
import FileListItem from "./FileList/FileListItem.vue";
import FileListSkeleton from "./FileList/FileListSkeleton.vue";
import CookiesNotification from "./Notifications/CookiesNotification.vue";

import FlickerPanel from "./Slide/FlickerPanel.vue";
import FlickerSlide from "./Slide/FlickerSlide.vue";
import VueVideo from "./Video/Video.vue";

import { PerfectScrollbar } from "vue2-perfect-scrollbar";

import VCountdown from "./Countdown/Countdown.vue";
import VReportTable from "./VReportTable/VReportTable.vue";

export default {
  PerfectScrollbar,
  VHeader,
  HeaderSearch,
  HeaderSearchPopup,
  HeaderMegamenuDesktop,
  HeaderMegamenuMobile,
  MegaMenu,
  BlockMenu,
  VAxis,
  AxisSidebar,
  AxisSkeleton,
  NewsLetter,
  VMessage,
  VEvents,
  DocumentEmbed,
  DocumentViewer,
  Emissions,
  Gallery,
  BvcStats,
  VTabs,
  TabItemList,
  TabContent,
  SearchPage,
  VAccordion,
  AccordionContent,
  VCounter,
  FileList,
  FileListItem,
  FileListSkeleton,
  CookiesNotification,
  FlickerPanel,
  FlickerSlide,
  VueVideo,
  VCountdown,
  VReportTable
};
