<template>
  <div class="search-page px-4 pt-7.5 xl:container xl:mx-auto relative">
    <form ref="searchForm" name="searchForm" method="get" class="flex flex-col gap-16 form-search-page">
      <div class="flex flex-col gap-4">
        <h2 class="text-3xl-2alt leading-tighter md:text-4xl-2alt font-semibold tracking-3tight md:tracking-normal">
          {{ localData.design.title }}
        </h2>
        <h3 class="font-medium text-lg leading-5tighter text-geb-gray-500">
          {{ localData.pager.total }} {{ 'resultados encontrados' | replaceLabel(localData.design.labels) }}
        </h3>
        <div v-html="localData.design.content" v-if="localData.design.content"></div>
      </div>

      <div class="flex flex-wrap md:flex-nowrap md:gap-7.5">
        <div class="search-page__sidebar w-full md:w-1/4 lg:w-78 lg:flex-shrink-0 mb-5">
          <h3 class="text-xl leading-2tighter font-semibold mb-3">
            {{ 'Refinar' | replaceLabel(localData.design.labels) }}
          </h3>
          <input
            :placeholder="'Término de búsqueda' | replaceLabel(localData.design.labels)"
            type="text"
            name="filters[keyword]"
            class="w-full h-14 sm:h-10.5 px-3 py-2 outline-none border border-geb-gray-50 focus:border-geb-dark-900 focus:border-opacity-50 rounded-1.5sm text-2sm leading-4tighter tracking-1.8tight"
            v-model="localData.applied_filters.keyword"
            v-on:keydown.enter.prevent="submitSearch"
          />
          <input type="hidden" name="recaptcha_token" :value="recaptchaToken" />

          <div v-if="localData.design.sorting_options && localData.design.sorting_options.length" class="sorting-container mt-5">
            <h4 class="text-2lg leading-4tighter mb-1.5">
              {{ 'Ordenar por' | replaceLabel(localData.design.labels) }}
            </h4>
            <div v-for="(item, index) in localData.design.sorting_options" :key="`sorting-opt-${index}`" class="search-sidebar__option mb-2">
              <label v-on:click="submitSearch">
                <input name="sort" :value="item.value" type="radio" v-bind:checked="localData.design.sorted_by == item.value" />
                {{ item.name | replaceLabel(localData.design.labels) }}
              </label>
            </div>
          </div>

          <div v-for="(aggrItem, aggIndex) in dataAggregations" :key="`aggr-container-${aggIndex}`" class="filter-container mt-3.25">
            <h4 class="text-2lg leading-4tighter mb-1.5">
              {{ aggrItem.name | replaceLabel(localData.design.labels) }}
            </h4>
            <div v-for="(item, index) in aggrItem.entries" :key="`filter-opt-${aggIndex}-${index}`" class="search-sidebar__option mb-2">
              <label :for="`filter-opt-${aggIndex}-${item.value}`">
                <input
                  :id="`filter-opt-${aggIndex}-${item.value}`"
                  :name="`filters[${aggIndex}][]`"
                  :value="item.value"
                  :type="aggrItem.multiple ? 'checkbox' : 'radio'"
                  v-on:change="submitSearch"
                  v-bind:checked="localData.applied_filters[aggIndex] && localData.applied_filters[aggIndex].indexOf(item.value) >= 0"
                />
                {{ item.name | replaceLabel(localData.design.labels) }} ({{ item.count }})
              </label>
            </div>
          </div>
        </div>

        <div v-if="localData.items" class="search-page__results md:w-3/4 lg:w-full mb-5">
          <div class="results-container">
            <a v-for="(item, index) in localData.items" :key="index" :href="item.link" class="search-results__item mb-9">
              <span class="content-type uppercase">{{ item.type }}</span>
              <h3 class="content-link">{{ item.title }}</h3>
              <p class="content-resume" v-html="item.resume.trim()"></p>
            </a>
          </div>

          <div class="pagination-container text-geb-green-secondary" v-if="localData.pager.last_page > 1">
            <a v-if="localData.pager.current_page > localData.pager.previous_page" @click.prevent="getUpdatedPageLink(localData.pager.previous_page)" class="page-link prev hover:text-geb-green-900 clickable-link">
              <i class="link-icon" v-icon:arrow-right-link-alt></i> {{ 'Anterior' | replaceLabel(localData.design.labels) }}
            </a>
            <span class="page-number">{{ localData.pager.current_page }}</span>
            <a v-if="localData.pager.has_next" @click.prevent="getUpdatedPageLink(localData.pager.next_page)" class="page-link next hover:text-geb-green-900 clickable-link">
              {{ 'Siguiente' | replaceLabel(localData.design.labels) }} <i class="link-icon" v-icon:arrow-right-link-alt></i>
            </a>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { generateRecaptchaToken } from '../../utils/recaptcha.js';

export default {
  name: "SearchPage",
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    apiUrl: {
      type: String,
      default: '',
    },
    recaptchaEnabled: {
      type: Boolean,
      default: false
    },
    recaptchaPublicKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isLoading: false,
      recaptchaToken: '',
      localData: JSON.parse(JSON.stringify(this.data)),
    };
  },
  filters: {
    replaceLabel(value, labelsMap) {
      return labelsMap[value.toLowerCase()] ?? value;
    }
  },
  computed: {
    dataAggregations() {
      const asArray = Object.entries(this.localData.aggregations || {});
      const filtered = asArray.filter(([key, value]) => !!value);
      return Object.fromEntries(filtered);
    }
  },
  methods: {
    async submitSearch() {
      if (this.isLoading) return;
      this.isLoading = true;

      await generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(async (recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;

        const form = this.$refs.searchForm;
        const formData = new FormData(form);

        formData.set('recaptcha_token', recaptchaToken);
        const updatedData = [...formData.entries()];
        const formString = updatedData.map((x) => `${x[0]}=${encodeURIComponent(x[1])}`).join("&");

        const response = await fetch(`${this.apiUrl}?${formString}`);
        this.localData = await response.json(); // Actualiza la copia local de `data`

        const actualUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
        window.history.pushState({ path: `${actualUrl}?${formString}` }, "", `${actualUrl}?${formString}`);

        this.isLoading = false;
      });
    },
    async getUpdatedPageLink(page) {
      await generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(async (recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;

        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('page', page);
        searchParams.set('recaptcha_token', recaptchaToken);

        window.location.href = `${window.location.pathname}?${searchParams.toString()}`;
      });
    }
  }
};
</script>
