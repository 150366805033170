<template>
  <div class="fixed inset-0 hidden2 max-h-screen overflow-hidden opacity-0 invisible" :class="classHover" ref="main" @mouseenter="handlerMouseHoverGroup" @mouseleave="handlerMouseHoverGroup">
    <div class="bg-black bg-opacity-50 w-screen min-h-screen fixed z-10" @mouseenter="handlerMouseEnterBackdrop"></div>
    <div :class="classHover" class="opacity-0 invisible transition-opacity duration-700 ease-out bg-geb-blue-secondary inset-x-0 mx-5 megamenu max-w-megamenu lg:mx-auto my-11.5 pt-15.75 pb-13 pl-12 pr-9.5 rounded-xl absolute z-50" ref="subMain" @mouseenter="handlerMouseHoverMegaMenu" @mouseleave="handlerMouseHoverMegaMenu">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      isMouseEnterBackdrop: false,
      isMouseEnterMenu: false
    }
  },
  computed: {
    ...mapState({
      isSearching: state => state.search.isSearching
    }),
    classHover() {
      return {
        'group-focus:opacity-100 group-focus:visible': !this.isSearching,
        'group-hover:opacity-100 group-hover:visible': !this.isSearching && !this.isMouseEnterBackdrop,
        'group-hover:overflow-y-auto group-focus:overflow-y-auto': this.isMouseEnterMenu
      }
    }
  },
  methods: {
    handlerMouseHoverGroup(e) {
      if (e.type === "mouseleave") {
        this.scrollTopMain();
      }
      // document.getElementsByTagName('body')[0].classList.toggle('md:overflow-hidden');
    },
    handlerMouseEnterBackdrop() {
      this.scrollTopMain();
      /* if (this.isMedios) {
        //this.setDefaultTab();
      } */
      this.isMouseEnterBackdrop = true;
      const activeElement = document.activeElement;
      activeElement.blur();

      setTimeout(() => {
        this.isMouseEnterBackdrop = false;
      }, 150);
    },
    handlerMouseHoverMegaMenu(){
      this.isMouseEnterMenu = !this.isMouseEnterMenu;
    },
    scrollTopMain() {
      this.$refs.main.scrollTo(0, 0);
      const group = this.$refs.main.closest('.group');
      if (!!group) group.classList.remove('is-scrolling');
    },
    fixPositionMegamenu() {
      const header = document.getElementById('header');
      if (header) this.$refs.main.style.top = (header.offsetHeight / 16) + 'rem';
    },
    checkScroll(e) {
      const { main, subMain } = this.$refs;      
      const mainScrollTop = main.scrollTop;
      const subMainOffsetTop = subMain.offsetTop;
      const group = main.closest('.group');

      if (!!group) {
        if (mainScrollTop > subMainOffsetTop && !group.classList.contains('is-scrolling')) {
          group.classList.add('is-scrolling');
        }
        else if (mainScrollTop < subMainOffsetTop) {
          group.classList.remove('is-scrolling');
        }
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.fixPositionMegamenu);
    this.$refs.main.addEventListener('scroll', this.checkScroll);
    setTimeout(() => this.fixPositionMegamenu(), 500);
  }
};
</script>