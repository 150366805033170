export async function generateRecaptchaToken(recaptchaEnabled, recaptchaPublicKey) {
  if (!recaptchaEnabled) {
    return '';
  }
  
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha.execute(recaptchaPublicKey, { action: 'submit' })
        .then(token => {
          console.info('reCAPTCHA token generated successfully');
          resolve(token);
        })
        .catch(err => {
          console.error('Failed to generate reCAPTCHA token', err);
          reject(err);
        });
    });
  });
}