<template>
  <mega-menu>
    <div class="flex gap-10" v-if="config">
      <ul class="w-1/3 lg:w-77.5 flex-shrink-0">
        <li class="border-b border-white border-opacity-30 pb-1 -mt-px mb-1.5 flex items-center">
          <a :href="config.url" class="font-medium text-geb-link text-2base leading-loose tracking-3tight hover:underline flex items-center gap-2 pb-px">
            {{ config.name }}
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 1L1 11" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M2 1H11V10" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </a>
        </li>

        <template v-for="child in config.children">
          <li
            v-if="child.children.length > 0"
            v-collapsible
            :key="child.id"
            class="flex flex-col py-2 border-b border-white border-opacity-20 cursor-pointer"
          >
            <div class="flex justify-between items-center text-geb-link hover:text-white">
              <a :href="child.link" class="font-medium text-geb-link hover:underline text-sm">{{ child.title }}</a>
              <i class="arrow flex-shrink w-4 h-4 fill-none" v-icon:chevron-down></i>
            </div>
            <ul data-collapsible class="hidden pl-5 pr-7 w-full space-y-3 pt-4 pb-3.5">
              <li
                v-for="child_children in child.children"
                :key="child_children.id"
                class="font-medium text-geb-link text-2.5xs tracking-normal leading-none py-px hover:underline"
              >
                <a :href="child_children.link">{{ child_children.name }}</a>
              </li>
            </ul>
          </li>

          <li v-else :key="child.id" class="py-2 border-b border-white border-opacity-20">
            <a :href="child.link" class="font-medium text-geb-link text-sm tracking-normal hover:underline">{{ child.title }}</a>
          </li>
        </template>
      </ul>

      <div class="flex items-start flex-wrap gap-9" v-if="config.featured.length > 0">
        <a
          v-for="featured in config.featured"
          :key="featured.id"
          :href="featured.url"
          class="flex flex-col lg:flex-row gap-9 flex-grow ml-px"
        >
          <picture class="w-full lg:w-1/2 lg:max-w-megamenu-image lg:flex-shrink-0 flex justify-center">
            <img :src="featured.promo_image" :alt="featured.title" class="rounded-xl w-full h-megamenu-image object-cover object-center">
          </picture>

          <div class="w-full">
            <h1 class="text-white hover:text-geb-gray-25 text-xl leading-2tighter tracking-normal font-semibold mb-4.5">
              {{ featured.title }}
            </h1>
            <p class="text-white text-2xs leading-relaxed font-normal tracking-normal mb-10">
              {{ featured.description | parseXML | getPromoDescriptionFromXML }}
            </p>
            <div class="text-geb-link hover:text-geb-blue-primary flex items-end gap-2.5">
              <span class="w-6.5 pl-px pt-0.5" v-icon:arrow-right-link></span>
              <span class="text-2xs leading-none tracking-normal font-normal">{{ microcopyFeatured }}</span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </mega-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "HeaderMegamenuDesktop",
  props: {
    id: {
      type: String,
      required: true
    },
    microcopyFeatured: {
      type: String
    }
  },
  computed: {
    ...mapState({
      megamenus: state => state.header.megamenus
    }),
    config() {
      return this.megamenus.find(el => el.name.toLowerCase() === this.id.toLowerCase())
    }
  }
};
</script>