<template>
  <div class="flex items-center text-geb-dark-800">
    <form @submit.prevent="onSubmit" ref="form" class="form-search flex items-center border-0 md:border-b py-1 ml-1 transform transition-all ease-out duration-700" :class="[ isSearching ? 'w-auto md:w-44 lg:w-74' : 'w-0' ]">
      <input
        v-on:keyup="debouncedOnKeyup"
        @keydown.enter="onSubmit"
        name="filters[keyword]"
        type="text"
        :placeholder="placeholderSearch"
        v-model="currentValue"
        ref="input"
        class="hidden md:block text-geb-dark-900 text-2xs leading-5tighter outline-none w-full"
      />
      <input type="hidden" name="recaptcha_token" :value="recaptchaToken" />
      <i
        v-icon:close-x-circle
        class="w-7 h-7 md:w-6 md:h-6 text-geb-dark-700 fill-none md:text-current cursor-pointer"
        :class="[ isSearching ? 'opacity-100' : 'opacity-0' ]"
        @click="toggleSearch"
      ></i>
    </form>

    <div class="flex" v-if="!isSearching">
      <i
        v-if="isSearching"
        v-icon:close-x-circle
        class="w-6 h-6 cursor-pointer"
        @click="toggleSearch"
      ></i>
      <i
        v-else
        v-icon:search
        class="w-6 h-6 lg:h-5 lg:w-5 text-geb-dark-700 md:text-current cursor-pointer"
        @click="toggleSearch"
      ></i>
    </div>
    <transition name="fade">
      <header-search-popup
        @close="toggleSearch"
        :url-search="urlSearch"
        :results="results"
        :is-mobile="isMobile"
        :placeholder-search="placeholderSearch"
        :show-popup="showPopup"
        :recaptcha-enabled="recaptchaEnabled"
        :recaptcha-public-key="recaptchaPublicKey"
        v-show="showPopup"
      >
        <template v-slot:main-title>
          <slot name="search-main-title"></slot>
        </template>
        <template v-slot:topic-title>
          <slot name="search-topic-title"></slot>
        </template>
      </header-search-popup>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { EventBus } from "../../EventBus.js";
import debounce from 'lodash/debounce';
import { generateRecaptchaToken } from '../../utils/recaptcha.js';

export default {
  props: {
    placeholderSearch: {
      type: String,
      default: 'Buscar'
    },
    apiUrlSearch: {
      type: String,
      default: ''
    },
    urlSearch: {
      type: String,
      default: ''
    },
    recaptchaEnabled: {
      type: Boolean,
      default: false
    },
    recaptchaPublicKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      viewportWidth: 0,
      mediaQuery: 768,
      isLoading: false,
      results: {},
      recaptchaToken: ''
    };
  },
  computed: {
    ...mapState({
      currentSearchValue: state => state.search.currentSearchValue,
      isSearching: state => state.search.isSearching
    }),
    currentValue: {
      get() {
        return this.currentSearchValue;
      },
      set(input) {
        return this.$store.dispatch('updateSearchValue', input);
      }
    },
    showPopup() {
      return this.isSearching && (this.currentValue.length > 2 || this.isMobile);
    },
    isMobile() {
      return this.viewportWidth < this.mediaQuery;
    }
  },
  methods: {
    toggleSearch() {
      this.$store.dispatch('updateSearchValue', '');
      this.$store.commit('setIsSearching', !this.isSearching);
      document.getElementById('header').classList.toggle('is-searching');

      if (this.isSearching) {
        EventBus.$emit('close-menu');
        setTimeout(() => {
          this.$refs.input.focus();
        }, 200);
      }
    },
    calculateViewportWidth() {
      this.viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    },
    async onKeyup() {
      if (this.isSearching && (this.currentValue.length > 2 || this.isMobile)) {
        await generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(async (recaptchaToken) => {
          this.recaptchaToken = recaptchaToken;
          const response = await fetch(`${this.apiUrlSearch}?filters[keyword]=${this.currentValue}&recaptcha_token=${recaptchaToken}`);
          this.results = await response.json();
        });
      }
    },
    debouncedOnKeyup: debounce(async function() {
      await this.onKeyup();
    }, 500),
    async onSubmit() {
      await generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then((recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;
        const searchParams = new URLSearchParams({
          'filters[keyword]': this.currentValue,
          'recaptcha_token': recaptchaToken
        });
        window.location.href = `${this.urlSearch}?${searchParams.toString()}`;
      });
    }
  },
  async mounted() {
    window.addEventListener('resize', this.calculateViewportWidth);
    this.calculateViewportWidth();
    EventBus.$on('close-search', () => {
      if (this.isSearching) this.toggleSearch();
    });

    generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(async (recaptchaToken) => {
      this.recaptchaToken = recaptchaToken;
    });
  }
};
</script>
