<template>
  <div class="absolute inset-0 px-3 min-h-screen overflow-x-hidden" ref="main">
    <div class="bg-black bg-opacity-50 w-screen h-screen fixed left-0 right-0 z-0" @click="$emit('close')"></div>
    <div v-if="!isMobile" class="px-4 lg:p-0 xl:container xl:mx-auto">
      <perfect-scrollbar :options="{ wheelPropagation: false }" v-if="!!currentSearchValue" class="bg-white overflow-y-scroll max-w-3xl lg:max-w-search max-h-search my-11 ml-auto mr-3 pt-10 pb-12 pl-12 pr-10 rounded-xl relative z-10">
        <div class="flex flex-col">
          <h3 class="font-semibold text-xl leading-2tighter"><slot name="main-title"></slot> “<a @click.prevent="onLinkClick(url)" class="text-geb-green-secondary clickable-link">{{ currentSearchValue }}</a>”</h3>

          <hr class="mt-8 mb-7" v-if="results.aggregations && results.aggregations.tags && results.aggregations.tags.entries && results.aggregations.tags.entries.length > 0">

          <div class="flex flex-col gap-1" v-if="results.aggregations && results.aggregations.tags && results.aggregations.tags.entries && results.aggregations.tags.entries.length > 0">
            <slot name="topic-title"></slot>
            <ul class="text-2xs leading-5tighter text-geb-green-secondary">
              <li v-for="(item, index) in results.aggregations.tags.entries" :key="index">
                <a @click.prevent="onLinkClick(`${urlSearch}?filters[tags][]=${item.value}`)" class="hover:underline clickable-link">{{ item.value }}</a>
              </li>
            </ul>
          </div>

          <hr class="my-8.5" v-if="results.items && results.items.length > 0">

          <div class="flex flex-col space-y-9.5" v-if="results.items && results.items.length > 0">
            <a :href="item.link" class="flex flex-col gap-1 group" v-for="(item, index) in results.items" :key="index">
              <h4 class="font-medium text-2lg leading-4tighter text-geb-green-secondary group-hover:text-geb-dark-800 group-hover:underline">{{ item.title }}</h4>
              <p class="text-2xs leading-5tighter text-geb-dark-900" v-html="item.resume.trim()"></p>
            </a>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
    <div v-else class="relative z-10 -mx-3">
      <div class="bg-geb-blue-secondary px-4 pt-3.5 pb-4.5">
        <form @submit.prevent="onSubmit" :action="urlSearch" method="GET" class="form-search flex items-center gap-4 border border-geb-gray-50 bg-trueGray-50 rounded-lg pl-4 pr-4.5 py-3 ml-px my-px" ref="form">
          <input name="filters[keyword]" type="text" :placeholder="placeholderSearch" v-model="currentValue" ref="input" class="text-2xs leading-loose text-geb-gray-800 bg-transparent outline-none flex-grow">
          <input type="hidden" name="recaptcha_token" :value="recaptchaToken">
          <i v-icon:search class="w-6 h-6 text-geb-gray-250 cursor-pointer" @click="onSubmit"></i>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { generateRecaptchaToken } from '../../utils/recaptcha.js';

export default {
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    placeholderSearch: {
      type: String,
      default: 'Buscar'
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    results: {
      type: Object,
      default: {}
    },
    urlSearch: {
      type: String,
      default: '/'
    },
    recaptchaEnabled: {
      type: Boolean,
      default: false
    },
    recaptchaPublicKey: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      value: '',
      recaptchaToken: ''
    }
  },
  computed: {
    ...mapState({
      currentSearchValue: state => state.search.currentSearchValue
    }),
    currentValue: {
      get() {
        return this.currentSearchValue;
      },
      set(input) {
        return this.$store.dispatch('updateSearchValue', input);
      }
    },
    url: function() {
      return `${this.urlSearch}?filters[keyword]=${this.currentSearchValue}`;
    }
  },
  watch: {
    showPopup(newValue) {
      if (this.isMobile && !!newValue) {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 500);
      }
    }
  },
  emits: ['close'],
  methods: {
    async onLinkClick(link) {
      await generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(async (recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;
        window.location.href = `${link}&recaptcha_token=${recaptchaToken}`;
      });
    },
    async onSubmit() {
      await generateRecaptchaToken(this.recaptchaEnabled, this.recaptchaPublicKey).then(async (recaptchaToken) => {
        this.recaptchaToken = recaptchaToken;
        this.$nextTick(() => {
          this.$refs.form.submit();
        });
      });
    },
    goToResults() {
      if (!!this.currentSearchValue) {
        window.location.href = this.url;
      }
    },
    fixPositionPopup() {
      const header = document.getElementById('header');
      if (header && this.$refs.main) this.$refs.main.style.top = (header.offsetHeight / 16) + 'rem';
    }
  },
  mounted () {
    window.addEventListener('resize', this.fixPositionPopup);
    setTimeout(() => this.fixPositionPopup(), 500);
  }
}
</script>
